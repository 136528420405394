var html = require('choo/html')

module.exports = thanks

function thanks (state, emit) {
  return html`
    <body>
      <main class="Thanks">
        <div class="Thanks-text">
          <h1 class="Thanks-title">Tack!</h1>
          <p>
            Vi kontaktar dig nÃ¤r urvalet till Designstipendiet Ã¤r beslutat.
            Svaret kommer i bÃ¶rjan av juli, 2024.
          </p>
          <p>Fler Ã¤n en person kan fÃ¥ stipendet, sÃ¥ tipsa gÃ¤rna en kompis.</p>
        </div>
      </main>
    </body>
  `
}
