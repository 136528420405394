var html = require('choo/html')
var { i18n } = require('../../components/base')

var text = i18n()

module.exports = home

const DEADLINE = new Date('2024-06-22')

function home (state, emit) {
  emit('meta', {
    title: text`SITE_NAME`,
    description: text`SITE_DESCRIPTION`,
    'og:image': '/share-image.png'
  })

  return html`
    <body>
      <main class="Info js-scroll">
        <div class="Info-main">
          <div class="Info-content">
            <p>
              Designstipendiet tÃ¤cker kostnaden fÃ¶r utbildningen, men innebÃ¤r
              ocksÃ¥ att stipendiaten ges mÃ¶jlighet att anvÃ¤nda jurygruppen som
              bollplank under studietiden.
            </p>
            <p>
              KvÃ¤llsskolan pÃ¥ Beckmans har tre inriktningar: Form, Mode och
              Visuell Kommunikation. Utbildningen Ã¤r fÃ¶rberedande och vilar pÃ¥
              konstnÃ¤rlig grund.
            </p>
            <p>
              KvÃ¤llsskolan erbjuder grundlÃ¤ggande orientering i Ã¤mnen som
              exempelvis mÃ¶belformgivning, mÃ¶nsterkomposition, modedesign,
              fotografi, grafisk design och teckning.
            </p>
            <p>
              Familjen Robert Weils Stiftelse vill medverka till att skolan
              fortsÃ¤tter att vara den kreativa samlingsplats den varit sen 1939.
              En hÃ¶gskola dÃ¤r en mÃ¥ngfald av erfarenheter och berÃ¤ttelser Ã¤r med
              och skapar vÃ¥r gemensamma framtid i en demokratisk och hÃ¥llbar
              riktning.
            </p>
            <p>DÃ¤rfÃ¶r har vi instiftat Designstipendiet.</p>
            <nav>
              <a href="/" class="Info-menu">Tillbaka</a>
            </nav>
          </div>
        </div>
        <div class="Info-sidebar">
          ${DEADLINE > Date.now()
            ? html`
                <ol class="Info-instructions">
                  <li class="Info-step">
                    SÃ¶k till KvÃ¤llsskolan pÃ¥ <a href="https://beckmans.se/studera/forberedande-utbildning-kvallsskolan/">beckmans.se</a>.
                  </li>
                  <li class="Info-step">
                    SÃ¶k sen <a href="/ansok">Designstipendiet</a>.
                  </li>
                  <li class="Info-step">
                    Blir du utvald sÃ¥ bekostar stipendiet utbildningen. Du fÃ¥r
                    ocksÃ¥ jurygruppen som bollplank under Ã¥ret.
                  </li>
                </ol>
                <div class="Info-footer">
                  Sista ansÃ¶kningsdag 22 juni 2024 och besked om stipendiet ges
                  kort dÃ¤r efter.
                </div>
              `
            : html`
                <ol class="Info-instructions">
                  <li>Ãrets ansÃ¶kning Ã¤r nu stÃ¤ngd.</li>
                </ol>
              `}
        </div>
      </main>
    </body>
  `
}
